import React, { FC } from "react";

type ClinicalBloodTestInputProps = {
  intl: any;
  size: string;
  title: string;
  register: any;
  name: string;
  error: any;
};

const ClinicalBloodTestInput: FC<ClinicalBloodTestInputProps> = ({
  intl,
  size,
  title,
  register,
  name,
  error,
}) => {
  return (
    <>
      {size !== " " ? (
        <div className={`mb-3 sm:w-96 xs:w-[${size}rem]`}>
          <label className="flex form-label inline-block mb-2 font-['gabdugi'] text-2xl justify-start">
            {intl.formatMessage({ id: title })}
          </label>
          <input
            type="text"
            {...register(name, { required: `Поле не может быть пустым` })}
            className="form-control block w-full px-3 lg:py-1.5 xs:py-[0.2rem] text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-[#DA6A00]
                        rounded transition ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-[#DA6A00] focus:outline-none"
            id="exampleFormControlInput1"
            placeholder=""
          />
          <p className="text-[14px] text-left text-red-600">
            {error && error.message}
          </p>
        </div>
      ) : (
        <div className="flex sm:w-96 xs:w-[16rem] flex-row justify-between items-center   mx-0">
          <div className="mb-3 xl:w-40 sm:w-[6.5rem] xs:w-[7rem]">
            <label
              htmlFor="exampleFormControlInput1"
              className="flex form-label inline-block mb-2 font-['gabdugi'] text-2xl justify-start"
            >
              {intl.formatMessage({ id: title })}
            </label>
            <input
              type="text"
              {...register(name, { required: `Поле не может быть пустым` })}
              className="form-control block xl:w-40 sm:w-[6.5rem] xs:w-[7rem] px-3 lg:py-1.5 xs:py-[0.2rem] text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-[#DA6A00]
                            rounded transition ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-[#DA6A00] focus:outline-none"
              id="exampleFormControlInput1"
              placeholder=""
            />
            <p className="text-[14px] text-left text-red-600">
              {error && error.message}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ClinicalBloodTestInput;
