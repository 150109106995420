import * as React from "react";
import Layout from "../components/Layout/Layout";
import parse from "html-react-parser";
import { clinicalBloodTestInputs } from "../utils/analysisInputs";
import ClinicalBloodTestInput from "../components/ClinicalBloodTestInput";
import { injectIntl } from "gatsby-plugin-intl";
import { useForm } from "react-hook-form";

//@ts-ignore
const clinicalBloodTest = ({ intl, pageContext }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    console.log("Submitted");
  };

  return (
    <Layout>
      <div className=" mb-10 mt-10 flex flex-col items-center ">
        <p className="text-center lg:text-4xl md:text-2xl sm:text-[1.4rem] font-['gabdugi'] text-[#DA6A00] font-semibold  sm:mb-4 md:font-bold xs:hidden sm:flex ">
          {parse(
            intl.formatMessage({
              id: "ServiceMenu.AnalysisAndPrices.ClinicalBloodTest.ClinicalBloodTestHeader",
            })
          )}
        </p>
        <div className="flex items-start mt-8 mb-8 justify-between items-center  sm:w-[40rem] md:w-[45rem] lg:w-[55rem] xs:flex-col sm:flex-row md:flex-row lg:flex-row  ">
          <div className="flex flex-col justify-center">
            <form onSubmit={handleSubmit(onSubmit)}>
              {clinicalBloodTestInputs.map((test: any) => (
                <ClinicalBloodTestInput
                  intl={intl}
                  size={test.size}
                  title={test.title}
                  register={register}
                  name={test.name}
                  error={errors && errors[test.name]}
                />
              ))}
              <div className="flex sm:w-96 xs:w-[16rem] flex-row justify-start gap-4 items-center   mb-3">
                <label>
                  <input
                    type="checkbox"
                    className=" w-8 lg:h-8 xs:h-[1.4rem] border border-[#DA6A00] accent-[#DA6A00] rounded-sm  focus:outline-none transition duration-200 cursor-pointer"
                  />
                </label>
                <label
                  htmlFor="exampleFormControlInput1"
                  className="flex form-label inline-block mb-2 font-['gabdugi']  justify-start items-start text-base"
                >
                  {intl.formatMessage({ id: "ApplicationForm.Agreement" })}
                </label>
              </div>
              <div className="flex sm:w-96 xs:w-[16rem] flex-row justify-between gap-4 items-center   mb-3">
                <p className=" font-['gabdugi']  text-[1.25rem]">
                  {intl.formatMessage({ id: "ApplicationForm.Cost" })}
                </p>
                <p className=" font-['gabdugi']  text-[1.25rem]">6 500 ₸ </p>
              </div>
              <div className="mb-3 sm:w-96 xs:w-[16rem]">
                <button className="w-full lg:h-12 xs:h-[2rem] rounded font-['gabdugi'] font-bold bg-[#DA6A00] text-white text-center text-2xl">
                  {intl.formatMessage({ id: "ServiceMenu.PCR.Apply" })}
                </button>
              </div>
            </form>
          </div>
          <div className="flex flex-col sm:w-full xs:w-[16.5rem] xs:mt-4 sm:mt-0 sm:ml-8 ">
            <div className="flex flex-row items-center gap-8 w-1/2 justify-between font-['gabdugi']">
              {parse(
                intl.formatMessage({
                  id: "ServiceMenu.AnalysisAndPrices.ClinicalBloodTest.ClinicalBloodTestMaterial",
                })
              )}
            </div>
            <div className="flex flex-row items-center gap-8 w-1/2 justify-between font-['gabdugi']">
              {parse(
                intl.formatMessage({
                  id: "ServiceMenu.AnalysisAndPrices.ClinicalBloodTest.ClinicalBloodTestTerm",
                })
              )}
            </div>
            <div className="font-['gabdugi']">
              {parse(
                intl.formatMessage({
                  id: "ServiceMenu.AnalysisAndPrices.ClinicalBloodTest.ClinicalBloodTestDescription",
                })
              )}
              <p className="font-['gabdugi'] text-[#DA6A00] font-bold underline mt-4 text-2xl">
                {intl.formatMessage({ id: "ApplicationForm.Recommendations" })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(clinicalBloodTest);
