export const clinicalBloodTestInputs = [
  {
    size: "16",
    title: "ApplicationForm.FullName",
    name: "full-name",
  },
  {
    size: "16",
    title: "ApplicationForm.IIN",
    name: "iin",
  },
  {
    size: "16",
    title: "ApplicationForm.PhoneNumber",
    name: "phone-number",
  },
  {
    size: " ",
    title: "ApplicationForm.Date",
    name: "date",
  },
  {
    size: " ",
    title: "ApplicationForm.Time",
    name: "time",
  },
  {
    size: "16",
    title: "ApplicationForm.EMailAddress",
    name: "e-mail",
  },
];
